@if (counterValue || (showZeroNumberValues() && counterValue === 0)) {
  <span
    class="inline-block h-[20px] w-[24px] rounded text-center text-sm"
    [ngClass]="{
      'bg-white': counterType === 'negative',
      'bg-president-50': counterType === 'default',
      'text-primary': counterType === 'negative' || counterType === 'default',
      'bg-vermillon-500 text-white': counterType === 'notification'
    }"
    [attr.data-testid]="testId() ? 'tab-counter-' + testId() : false"
  >
    {{ counterValue }}
  </span>
}
