@if (link(); as link) {
  <a
    class="tab-pill"
    [ngClass]="{active: active}"
    [attr.id]="tabId"
    [routerLink]="link"
    [routerLinkActive]="'active'"
    [attr.data-testid]="tabId"
  >
    <ng-container [ngTemplateOutlet]="content" />
  </a>
} @else {
  <button class="tab-pill" [ngClass]="{active: active}" type="button" [attr.id]="tabId" [attr.data-testid]="tabId">
    <ng-container [ngTemplateOutlet]="content" />
  </button>
}
<ng-template #content>
  <ng-content />
  <ng-content select="cft-tab-counter" />
</ng-template>

<ng-template #tabContent>
  <ng-content select="*[cft-tab-pill-content]" />
</ng-template>
