import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  Output,
  PLATFORM_ID,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {isPlatformBrowser} from '@angular/common'
import {Tab} from '../tab.interface'
import {TabCounter} from '../tab-counter/tab-counter.component'

@Component({
  selector: 'cft-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: Tab, useExisting: forwardRef(() => TabComponent)}],
})
export class TabComponent implements Tab, AfterViewInit {
  @Input() tabId?: string
  @Input() text!: string
  @Input() link?: string
  @Input() counter?: TabCounter
  @Input() icon?: {name: string; collection: string} | IconDefinition

  /**
   * Active state of the tab
   * The parent component is responsible for setting the active state
   * This is because the parent is aware of the other tabs as well, and only one tab can be active at the same time
   */
  @Input()
  get active() {
    return this._active
  }
  set active(active: boolean) {
    this._active = active
    // somehow, even when the value changes, changeDetection doesn't trigger
    // mark it for check explicitly makes it work
    this.cdr.markForCheck()
  }
  private _active = false

  /* Inverts the tab background color and text color to blue and white respectively */
  @Input() inverted = false

  /* Use hideTextOnTabletDevices and hideTextOnMobileDevices not at the same time */
  @Input() hideTextOnMobileDevices = false
  @Input() hideTextOnTabletDevices = false
  /* hideText will override hideTextOnMobileDevices and hideTextOnTabletDevices */
  @Input() hideText = false
  @Input() disableAnimationDelay = false
  @Input() showZeroNumberValues = false

  @Output() tabActivated = new EventEmitter<boolean>()

  @ViewChild('content') public templateRef!: TemplateRef<unknown>
  @ViewChild('anchorElement') private anchorElement?: ElementRef<HTMLAnchorElement>

  readonly activeClass = 'active'

  showClickAnimation = false

  constructor(
    private readonly cdr: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private readonly platformId: Record<string, unknown>,
  ) {}

  get dynamicIcon(): {name: string; collection: string} | undefined {
    if (this.icon?.['name'] && this.icon?.['collection']) {
      return this.icon as {name: string; collection: string}
    }
  }

  get faIcon(): IconDefinition | undefined {
    if (this.icon?.['icon']) {
      return this.icon as IconDefinition
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId) && this.anchorElement?.nativeElement) {
      //This monitors changes on the class attribute of the tab anchor links. As soon as it changes,
      //we check if the tab has an "active" class meaning it was triggered by [routerLinkActive].
      //I tried using "router.events" but doesn't work because that is already fired before the <cft-tab/> is even loaded.
      const observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          const target = mutation.target as HTMLAnchorElement
          if (mutation.attributeName === 'class' && target.classList.contains(this.activeClass)) {
            this.tabActivated.emit(true)
          }
        })
      })
      observer.observe(this.anchorElement!.nativeElement, {attributes: true})
    }
  }

  tabClick(e: MouseEvent): boolean {
    e.preventDefault()
    e.stopPropagation()
    if (this.active) return false

    this.tabActivated.emit(true)

    return false
  }
}
