import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  effect,
  EventEmitter,
  input,
  OnDestroy,
  Output,
  QueryList,
} from '@angular/core'
import {Subject} from 'rxjs'
import {takeUntil} from 'rxjs/operators'
import {Tab} from './tab.interface'

@Component({
  selector: 'cft-tabs',
  templateUrl: './tabs.component.html',
  styles: [':host {display: block}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabsComponent implements OnDestroy, AfterViewInit {
  @Output() scrolling = new EventEmitter<{start: boolean; end: boolean; left: boolean; right: boolean}>()
  @Output() tabActivated = new EventEmitter<Tab>()

  /**
   * Allows you to control the active tab from a parent component.
   *
   * This is needed because this component controls the active state of its children.
   */
  selectedTabId = input<string>()
  showBorder = input(true)

  @ContentChildren(Tab) tabs!: QueryList<Tab>

  selectedTabId$ = new Subject<string>()
  selectedTab?: Tab

  private scrollLeft = 0
  private readonly unsubscribe$ = new Subject<void>()

  constructor(private readonly cdr: ChangeDetectorRef) {
    effect(() => {
      const tabId = this.selectedTabId()
      if (tabId) {
        this.selectedTabId$.next(tabId)
      }
    })
  }

  ngAfterViewInit(): void {
    // Although "this.tabs" is already available on ngAfterContentInit(), we do it on ngAfterViewInit() because it's
    // the last suitable hook in the lifecycle, and we can thus be sure that any child components relying on @ViewChildren
    // will also be available.
    this.startControllingTabsActiveState()
  }

  private startControllingTabsActiveState() {
    // this component is responsible for setting the active state of the tabs
    // this is handled at this level because it's aware of all the tabs
    this.tabs.toArray().forEach(t => {
      // listen for changes, can occur due to navigation or tab clicked
      t.tabActivated.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        this.activateTab(t)
      })

      // initial activation, based on @Input state
      if (t.active) {
        this.activateTab(t)
      }
    })

    this.selectedTabId$.pipe(takeUntil(this.unsubscribe$)).subscribe(tabId => {
      const tab = this.tabs.find(t => t.tabId === tabId)

      if (tab) {
        // only activate if the tab has changed
        if (this.selectedTab?.tabId !== tab.tabId) {
          this.activateTab(tab)
        }
      } else {
        console.error('Tab with id', tabId, 'not found!')
      }
    })
  }

  private activateTab(tab: Tab) {
    if (this.selectedTab) {
      // only one tab active at the same time
      this.selectedTab.active = false
    }
    tab.active = true
    this.selectedTab = tab
    this.cdr.detectChanges()

    // notify listeners
    this.tabActivated.emit(tab)
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
    this.selectedTabId$.complete()
  }

  scroll(event: Event): void {
    const div = event.target! as HTMLDivElement
    this.scrolling.emit({
      start: div.scrollLeft === 0,
      end: div.scrollLeft + div.offsetWidth === div.scrollWidth,
      left: div.scrollLeft < this.scrollLeft,
      right: div.scrollLeft > this.scrollLeft,
    })
    this.scrollLeft = div.scrollLeft
  }
}
