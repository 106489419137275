import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostListener,
  input,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import {Tab} from '../tab.interface'

@Component({
  selector: 'cft-tab-pill',
  templateUrl: './tab-pill.component.html',
  styles: `
    :host .tab-pill {
      @apply text-sm py-xs px-sm inline-flex items-center gap-2xs text-grey-700 rounded-lg;
      @apply text-nowrap;
    }
    :host .tab-pill.active {
      @apply text-president-600 bg-grey-200;
    }
    :host(:hover) .tab-pill {
      @apply text-president-600;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{provide: Tab, useExisting: forwardRef(() => TabPillComponent)}],
})
export class TabPillComponent implements Tab {
  @ViewChild('tabContent')
  public templateRef!: TemplateRef<unknown>

  @Input()
  active = false

  tabActivated: EventEmitter<boolean> = new EventEmitter<boolean>()

  // Input needs to be decorator due to compatibility with the `Tab` interface
  @Input() tabId?: string
  link = input<string>()

  @HostListener('click', ['$event'])
  activated() {
    if (!this.active) this.tabActivated.emit(true)
  }
}
