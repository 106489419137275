import {ChangeDetectionStrategy, Component, input} from '@angular/core'

@Component({
  selector: 'cft-tab-counter',
  templateUrl: './tab-counter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabCounterComponent {
  counter = input.required<TabCounter>()
  testId = input<string>()
  showZeroNumberValues = input<boolean>()

  get counterType(): 'default' | 'notification' | 'negative' {
    const counter = this.counter()
    return typeof counter === 'number' ? 'default' : counter.type
  }

  get counterValue(): number {
    const counter = this.counter()
    return typeof counter === 'number' ? counter : counter.value
  }
}

export type TabCounter = number | {value: number; type: 'default' | 'notification' | 'negative'}
