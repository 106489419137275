@if (link) {
  <a
    #anchorElement
    cftAnimationState
    [disableAnimationDelay]="disableAnimationDelay"
    (animate)="showClickAnimation = $event === 'show'"
    [attr.id]="tabId"
    [routerLink]="link"
    [routerLinkActive]="activeClass"
    class="tab text-base"
    [class.tab-inverted]="inverted"
    (click)="tabClick($event)"
    [class.clicked]="showClickAnimation"
    [attr.data-testid]="tabId"
  >
    <ng-container [ngTemplateOutlet]="tabTitle"></ng-container>
  </a>
} @else {
  <button
    cftAnimationState
    (animate)="showClickAnimation = $event === 'show'"
    [attr.id]="tabId"
    class="tab text-base"
    [class.tab-inverted]="inverted"
    (click)="tabClick($event)"
    [class.active]="active"
    [class.clicked]="showClickAnimation"
    [attr.data-testid]="tabId"
  >
    <ng-container [ngTemplateOutlet]="tabTitle"></ng-container>
  </button>
}

<ng-template #tabTitle>
  <span
    class="flex h-full items-center space-x-xs rounded-t-3 px-md pb-xs pt-2xs"
    [class.bg-grey-50]="showClickAnimation && !inverted"
  >
    @if (dynamicIcon) {
      <cft-dynamic-icon [icon]="dynamicIcon"></cft-dynamic-icon>
    }
    @if (faIcon) {
      <fa-icon [icon]="faIcon"></fa-icon>
    }
    @if (text) {
      <span
        class="inline-block whitespace-nowrap"
        [ngClass]="{
          hidden: hideText || hideTextOnMobileDevices || hideTextOnTabletDevices,
          'md:inline-block': hideTextOnMobileDevices,
          'lg:inline-block': hideTextOnTabletDevices,
          'md:!hidden xl:!hidden': hideText
        }"
      >
        {{ text }}
      </span>
    }
    @if ((showZeroNumberValues && counter === 0) || counter) {
      <cft-tab-counter [testId]="text" [counter]="counter" [showZeroNumberValues]="showZeroNumberValues" />
    }
  </span>
  <span class="tab-line" [class.tab-inverted]="inverted"></span>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
