import {NgModule} from '@angular/core'
import {TabsComponent} from './tabs.component'
import {TabComponent} from './tab/tab.component'
import {CommonModule} from '@angular/common'
import {RouterModule} from '@angular/router'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {DirectivesModule} from '../../shared/directives/directives.module'
import {DynamicIconModule} from '../dynamic-icon/dynamic-icon.module'
import {DirectivesModule as CraftDirectivesModules} from '../directives/directives.module'
import {TabPillComponent} from './tab-pill/tab-pill.component'
import {TabCounterComponent} from './tab-counter/tab-counter.component'

@NgModule({
  imports: [CommonModule, RouterModule, FontAwesomeModule, DirectivesModule, DynamicIconModule, CraftDirectivesModules],
  declarations: [TabsComponent, TabComponent, TabPillComponent, TabCounterComponent],
  exports: [TabsComponent, TabComponent, TabPillComponent, TabCounterComponent],
})
export class TabsModule {}
